import React from "react";
import styled from "styled-components";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";

import { formatNumber } from "utils";

import { useGetTxStatusQuery } from "app/services/transaction";
import { TextMain, StatusStyled, TextSkeleton } from "common/styledGeneral";

export function Status({
  solTx,
  status,
  statusMgs,
}: {
  solTx: string;
  status: string;
  statusMgs?: string;
}) {
  const { data, error, isLoading } = solTx
    ? useGetTxStatusQuery(solTx)
    : { data: null, error: null, isLoading: false };
  return (
    <TextMain className="word-break mv-2">
      {status === "1" || status === "0x1" ? (
        <StatusStyled status="success">
          <CheckCircleFilled />
          <span
            style={{
              textTransform: "capitalize",
              color: "inherit",
            }}
          >
            Successful
          </span>
          {data?.data ? (
            <TextStyle>
              {data.data?.confirmationStatus && (
                <CapitalizeStyle
                  style={{
                    marginRight: 5,
                  }}
                >
                  {data.data.confirmationStatus}
                </CapitalizeStyle>
              )}
              {data.data?.confirmations &&
                `(${formatNumber(data.data.confirmations)} confirmations)`}
            </TextStyle>
          ) : (
            <TextStyle>
              <TextSkeleton />
            </TextStyle>
          )}
        </StatusStyled>
      ) : (
        <StatusStyled status="error">
          <CloseCircleFilled />
          &nbsp;
          <span
            style={{
              color: "inherit",
            }}
          >
            Fail with {statusMgs ? `${statusMgs}` : ""}
          </span>
          {/* with error {data.failReason ? data.failReason : ""} */}
        </StatusStyled>
      )}
    </TextMain>
  );
}

const TextStyle = styled.span`
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    width: 2px;
    height: 15px;
    background-color: ${({ theme }) => theme.border2};
  }
  @media (max-width: 550px) {
    &:before {
      display: none;
    }
  }
`;

const CapitalizeStyle = styled.span`
  text-transform: capitalize;
  margin-left: 5;
  margin-right: 5;
`;
